/*
 * @Author: zhangzhipeng
 * @Date: 2021-04-21 08:08:02
 * @LastEditors: zhangzhipeng
 * @LastEditTime: 2023-03-16 09:10:58
 * @Description: 更改html的
 */
import defaultSettings from '@config/defaultSettings';
import { set_rate } from '@/utils/global/zoom';
import { all } from '@config/proxy';
function dispatchEventStorage() {
  //同一页面中
  const signSetItem = localStorage.setItem;
  localStorage.setItem = function (key, val) {
    const setEvent = new Event('setItemEvent');
    // @ts-ignore
    setEvent.key = key;
    // @ts-ignore
    setEvent.newValue = val;
    window.dispatchEvent(setEvent);
    // @ts-ignore
    signSetItem.apply(this, arguments);
  };
}
export async function changeDom() {
  const oMeta = document.createElement('meta');
  oMeta.content = 'notranslate';
  oMeta.name = 'google';
  set_rate();

  document.getElementsByTagName('head')[0].appendChild(oMeta);
  if (process.env.NODE_ENV === 'development') {
    const bodyStyle = document.body.style;
    /*bodyStyle.fontFamily =
      "'宋体',Helvetica Neue,Helvetica,Arial,'Microsoft Yahei','Hiragino Sans GB','Heiti SC','WenQuanYi Micro Hei',sans-serif,SFMono-Regular, Consolas, 'Liberation Mono', Menlo, Courier, monospace ";*/
  }
  dispatchEventStorage();
  let jump = '';
  // 适应新的web服务器
  if (window.location.hash !== '') {
    if (
      window.location.pathname.indexOf('/api/') === -1 ||
      process.env.NODE_ENV !== 'development'
    ) {
      if (window.location.origin.indexOf('//localhost') !== -1) {
        // @ts-ignore
        jump = `//${window.location.host}/api/${REACT_APP_ENV.split('@')[0]}${
          window.location.hash
        }`;
      } else {
        let should_return = false;
        const keys = Object.keys(all);

        for (let index = 0; index < keys.length; index++) {
          const urls = all[keys[index]];
          for (let index2 = 0; index2 < urls.length; index2++) {
            let url = all[keys[index]][index2];
            url = url.indexOf(':') !== -1 ? url.split(':')[0] : url;
            // url = url.split(':')[0];
            if (
              window.location.origin.indexOf(url) !== -1 &&
              window.location.pathname !== `/api/${keys[index]}` &&
              !should_return
            ) {
              console.log(
                '跳转3',
                `//${window.location.host}/api/${keys[index]}${window.location.hash}`,
              );
              should_return = true;
              // @ts-ignore
              jump = `//${window.location.host}/api/${keys[index]}${window.location.hash}`;

              break;
            }
          }
          if (should_return) break;
        }
        if (!should_return) {
          console.log('跳转4');
          if (window.location.pathname.indexOf('/api/') === -1) {
            // @ts-ignore
            jump = `//${window.location.host}/api/beta${window.location.hash}`;
          } else {
            // @ts-ignore
            jump = ``;
          }
        }
      }
    }
    // if (window.location.hash === '#/') {
    //   // @ts-ignore
    //   window.location.href = `//${window.location.host}/api/${REACT_APP_ENV.split('@')[0]}#${
    //     defaultSettings.config.index.path
    //   }`;
    // }
  }
  if (REACT_APP_ENV && REACT_APP_ENV.split('@')[0] === 'zxapi' && jump !== '') {
    jump = `//${window.location.host}/api/zxapi${window.location.hash}`;
  }
  if (jump !== '') {
    window.location.href = jump;
  }
}
