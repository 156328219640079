// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from 'D:/YLX_PET_WEB/xinguanjia_web_antdpro/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/PageLoading/index';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BlankLayout' */'D:/YLX_PET_WEB/xinguanjia_web_antdpro/src/layouts/BlankLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/other",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__Other' */'D:/YLX_PET_WEB/xinguanjia_web_antdpro/src/layouts/Other'), loading: LoadingComponent}),
        "routes": [
          {
            "name": "video",
            "path": "/other/video",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Video' */'D:/YLX_PET_WEB/xinguanjia_web_antdpro/src/pages/Video'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "comprehensive-analysis",
            "path": "/other/comprehensive-analysis",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ComprehensiveAnalysis' */'D:/YLX_PET_WEB/xinguanjia_web_antdpro/src/pages/ComprehensiveAnalysis'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "operation-video",
            "path": "/other/operation-video",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OperationVideo' */'D:/YLX_PET_WEB/xinguanjia_web_antdpro/src/pages/OperationVideo'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/open-window",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__OpenWindow' */'D:/YLX_PET_WEB/xinguanjia_web_antdpro/src/layouts/OpenWindow'), loading: LoadingComponent}),
        "routes": [
          {
            "path": "/",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__Middleware' */'D:/YLX_PET_WEB/xinguanjia_web_antdpro/src/layouts/Middleware'), loading: LoadingComponent}),
            "routes": [
              {
                "path": "/open-window/business",
                "name": "business",
                "icon": "smile",
                "routes": [
                  {
                    "path": "/open-window/business/ecg",
                    "name": "ecg",
                    "icon": "smile",
                    "routes": [
                      {
                        "name": "ecg-reports-edit",
                        "path": "/open-window/business/ecg/ecg-reports-edit",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OpenWindow__Business__Ecg__EcgReportsEdit' */'D:/YLX_PET_WEB/xinguanjia_web_antdpro/src/pages/OpenWindow/Business/Ecg/EcgReportsEdit'), loading: LoadingComponent}),
                        "bussinessNo": 2000025,
                        "exact": true
                      },
                      {
                        "name": "screen-detail",
                        "path": "/open-window/business/ecg/screen-detail",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OpenWindow__Business__Ecg__ScreenDetail' */'D:/YLX_PET_WEB/xinguanjia_web_antdpro/src/pages/OpenWindow/Business/Ecg/ScreenDetail'), loading: LoadingComponent}),
                        "bussinessNo": 2000041,
                        "exact": true
                      },
                      {
                        "name": "ecg-reports-wholePreview",
                        "path": "/open-window/business/ecg/ecg-reports-edit/whole-preview",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OpenWindow__Business__Ecg__EcgReportsEdit__components__EcgWholePreview' */'D:/YLX_PET_WEB/xinguanjia_web_antdpro/src/pages/OpenWindow/Business/Ecg/EcgReportsEdit/components/EcgWholePreview'), loading: LoadingComponent}),
                        "exact": true
                      },
                      {
                        "name": "ecg-reports-printView",
                        "path": "/open-window/business/ecg/ecg-reports-edit/whole-preview/print-view",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OpenWindow__Business__Ecg__EcgReportsEdit__components__EcgWholePreview__PrintView' */'D:/YLX_PET_WEB/xinguanjia_web_antdpro/src/pages/OpenWindow/Business/Ecg/EcgReportsEdit/components/EcgWholePreview/PrintView'), loading: LoadingComponent}),
                        "exact": true
                      },
                      {
                        "name": "ekg",
                        "path": "/open-window/business/ecg/rhc-ecg-detail",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OpenWindow__Business__Ecg__EcgReportsEdit' */'D:/YLX_PET_WEB/xinguanjia_web_antdpro/src/pages/OpenWindow/Business/Ecg/EcgReportsEdit'), loading: LoadingComponent}),
                        "bussinessNo": 2000025,
                        "exact": true
                      },
                      {
                        "name": "ecg-detail",
                        "path": "/open-window/business/ecg/ecg-detail",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OpenWindow__Business__Ecg__EcgDetail' */'D:/YLX_PET_WEB/xinguanjia_web_antdpro/src/pages/OpenWindow/Business/Ecg/EcgDetail'), loading: LoadingComponent}),
                        "bussinessNo": 0,
                        "exact": true
                      },
                      {
                        "name": "editing-template",
                        "path": "/open-window/business/ecg/editing-template",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OpenWindow__Business__Ecg__EditingTemplate' */'D:/YLX_PET_WEB/xinguanjia_web_antdpro/src/pages/OpenWindow/Business/Ecg/EditingTemplate'), loading: LoadingComponent}),
                        "bussinessNo": 0,
                        "exact": true
                      },
                      {
                        "name": "redux-demo",
                        "path": "/open-window/business/ecg/redux-demo",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OpenWindow__Business__Ecg__ReduxDemo' */'D:/YLX_PET_WEB/xinguanjia_web_antdpro/src/pages/OpenWindow/Business/Ecg/ReduxDemo'), loading: LoadingComponent}),
                        "bussinessNo": 0,
                        "exact": true
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "path": "/",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__SecurityLayout' */'D:/YLX_PET_WEB/xinguanjia_web_antdpro/src/layouts/SecurityLayout'), loading: LoadingComponent}),
        "routes": [
          {
            "path": "/",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BasicLayout' */'D:/YLX_PET_WEB/xinguanjia_web_antdpro/src/layouts/BasicLayout'), loading: LoadingComponent}),
            "authority": [
              "admin",
              "user"
            ],
            "routes": [
              {
                "path": "/",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__Middleware' */'D:/YLX_PET_WEB/xinguanjia_web_antdpro/src/layouts/Middleware'), loading: LoadingComponent}),
                "routes": [
                  {
                    "path": "/",
                    "redirect": "/business/pet/interpretation-report",
                    "exact": true
                  },
                  {
                    "path": "/admin**",
                    "name": "admin",
                    "icon": "FundViewOutlined",
                    "id": 345,
                    "routes": [
                      {
                        "path": "/admin**/business",
                        "name": "business",
                        "icon": "BankOutlined",
                        "systemId": 16,
                        "id": 16,
                        "routes": [
                          {
                            "path": "/admin**/business/settings/system",
                            "name": "system",
                            "icon": "SettingOutlined",
                            "iconCls": "&#xe734;",
                            "id": 345,
                            "routes": [
                              {
                                "name": "warning",
                                "path": "/admin**/business/settings/system/warning",
                                "locale": "menu.business.settings.system.warning",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Settings__System__Warning' */'D:/YLX_PET_WEB/xinguanjia_web_antdpro/src/pages/Business/Settings/System/Warning'), loading: LoadingComponent}),
                                "bussinessNo": 1101050,
                                "exact": true
                              },
                              {
                                "name": "operation-log",
                                "path": "/admin**/business/settings/system/operation-log",
                                "locale": "menu.business.settings.system.operation-log",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Settings__System__OperationLog' */'D:/YLX_PET_WEB/xinguanjia_web_antdpro/src/pages/Business/Settings/System/OperationLog'), loading: LoadingComponent}),
                                "bussinessNo": 1101051,
                                "exact": true
                              }
                            ]
                          },
                          {
                            "path": "/admin**/business/ecg",
                            "name": "ecg",
                            "icon": "FundOutlined",
                            "iconCls": "&#xe61f;",
                            "id": 345,
                            "routes": [
                              {
                                "name": "users",
                                "locale": "menu.business.ecg.users",
                                "path": "/admin**/business/ecg/users",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Ecg__Users' */'D:/YLX_PET_WEB/xinguanjia_web_antdpro/src/pages/Business/Ecg/Users'), loading: LoadingComponent}),
                                "bussinessNo": 2000011,
                                "exact": true
                              },
                              {
                                "name": "ecg-reports",
                                "path": "/admin**/business/ecg/ecg-reports",
                                "locale": "menu.business.ecg.ecg-reports",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Ecg__EcgReports' */'D:/YLX_PET_WEB/xinguanjia_web_antdpro/src/pages/Business/Ecg/EcgReports'), loading: LoadingComponent}),
                                "bussinessNo": 2000025,
                                "exact": true
                              },
                              {
                                "name": "bindings",
                                "path": "/admin**/business/ecg/bindings",
                                "locale": "menu.business.ecg.bindings",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Ecg__Bindings' */'D:/YLX_PET_WEB/xinguanjia_web_antdpro/src/pages/Business/Ecg/Bindings'), loading: LoadingComponent}),
                                "bussinessNo": 2000024,
                                "exact": true
                              },
                              {
                                "name": "log-list",
                                "path": "/admin**/business/ecg/log-list",
                                "locale": "menu.business.ecg.log-list",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Ecg__LogList' */'D:/YLX_PET_WEB/xinguanjia_web_antdpro/src/pages/Business/Ecg/LogList'), loading: LoadingComponent}),
                                "bussinessNo": 2000021,
                                "exact": true
                              },
                              {
                                "name": "warnings",
                                "path": "/admin**/business/ecg/warnings",
                                "locale": "menu.business.ecg.warnings",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Ecg__Warnings' */'D:/YLX_PET_WEB/xinguanjia_web_antdpro/src/pages/Business/Ecg/Warnings'), loading: LoadingComponent}),
                                "bussinessNo": 2000015,
                                "exact": true
                              },
                              {
                                "name": "weak",
                                "path": "/admin**/business/ecg/weak",
                                "locale": "menu.business.ecg.weak",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Ecg__Weak' */'D:/YLX_PET_WEB/xinguanjia_web_antdpro/src/pages/Business/Ecg/Weak'), loading: LoadingComponent}),
                                "bussinessNo": 2000039,
                                "exact": true
                              },
                              {
                                "name": "screen",
                                "path": "/admin**/business/ecg/screen",
                                "locale": "menu.business.ecg.screen",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Ecg__Screen' */'D:/YLX_PET_WEB/xinguanjia_web_antdpro/src/pages/Business/Ecg/Screen'), loading: LoadingComponent}),
                                "bussinessNo": 2000041,
                                "exact": true
                              },
                              {
                                "name": "screen-user",
                                "path": "/admin**/business/ecg/screen-user",
                                "locale": "menu.business.ecg.screen-user",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Ecg__ScreenUser' */'D:/YLX_PET_WEB/xinguanjia_web_antdpro/src/pages/Business/Ecg/ScreenUser'), loading: LoadingComponent}),
                                "bussinessNo": 2000046,
                                "exact": true
                              },
                              {
                                "name": "sampling",
                                "path": "/admin**/business/ecg/sampling",
                                "locale": "menu.business.ecg.sampling",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Ecg__Sampling' */'D:/YLX_PET_WEB/xinguanjia_web_antdpro/src/pages/Business/Ecg/Sampling'), loading: LoadingComponent}),
                                "bussinessNo": 2000042,
                                "exact": true
                              },
                              {
                                "name": "device",
                                "path": "/admin**/business/ecg/device",
                                "locale": "menu.business.ecg.device",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Ecg__Device' */'D:/YLX_PET_WEB/xinguanjia_web_antdpro/src/pages/Business/Ecg/Device'), loading: LoadingComponent}),
                                "bussinessNo": 2000044,
                                "exact": true
                              },
                              {
                                "name": "application",
                                "path": "/admin**/business/ecg/report-apply",
                                "locale": "menu.business.ecg.apply",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Ecg__ReportApplication' */'D:/YLX_PET_WEB/xinguanjia_web_antdpro/src/pages/Business/Ecg/ReportApplication'), loading: LoadingComponent}),
                                "bussinessNo": 2000047,
                                "exact": true
                              }
                            ]
                          },
                          {
                            "path": "/admin**/business/online",
                            "name": "online",
                            "icon": "CommentOutlined",
                            "iconCls": "&#xe800;",
                            "id": 373,
                            "routes": [
                              {
                                "name": "analytic-orders",
                                "path": "/admin**/business/online/analytic-orders",
                                "locale": "menu.business.online.analytic-orders",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Online__AnalyticOrders' */'D:/YLX_PET_WEB/xinguanjia_web_antdpro/src/pages/Business/Online/AnalyticOrders'), loading: LoadingComponent}),
                                "bussinessNo": 2000032,
                                "exact": true
                              },
                              {
                                "name": "signature",
                                "path": "/admin**/business/online/signature",
                                "locale": "menu.business.online.signature",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Online__Signature' */'D:/YLX_PET_WEB/xinguanjia_web_antdpro/src/pages/Business/Online/Signature'), loading: LoadingComponent}),
                                "bussinessNo": 2000035,
                                "exact": true
                              },
                              {
                                "name": "interpretation-orders",
                                "path": "/admin**/business/online/interpretation-orders",
                                "locale": "menu.business.online.interpretation-orders",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Online__InterpretationOrders' */'D:/YLX_PET_WEB/xinguanjia_web_antdpro/src/pages/Business/Online/InterpretationOrders'), loading: LoadingComponent}),
                                "bussinessNo": 2000030,
                                "exact": true
                              },
                              {
                                "name": "intelligent-screen",
                                "path": "/admin**/business/online/intelligent-screen",
                                "locale": "menu.business.online.intelligent-screen",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Online__IntelligentScreen' */'D:/YLX_PET_WEB/xinguanjia_web_antdpro/src/pages/Business/Online/IntelligentScreen'), loading: LoadingComponent}),
                                "bussinessNo": 2000043,
                                "exact": true
                              }
                            ]
                          },
                          {
                            "path": "/admin**/business/statistics",
                            "name": "statistics",
                            "icon": "AreaChartOutlined",
                            "iconCls": "&#xe626;",
                            "id": 237,
                            "routes": [
                              {
                                "name": "user-area",
                                "path": "/admin**/business/statistics/user-area",
                                "locale": "menu.business.statistics.user-area",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Statistics__UserArea' */'D:/YLX_PET_WEB/xinguanjia_web_antdpro/src/pages/Business/Statistics/UserArea'), loading: LoadingComponent}),
                                "bussinessNo": 2100001,
                                "exact": true
                              },
                              {
                                "name": "company-usage",
                                "path": "/admin**/business/statistics/company-usage",
                                "locale": "menu.business.statistics.company-usage",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Statistics__CompanyUsage' */'D:/YLX_PET_WEB/xinguanjia_web_antdpro/src/pages/Business/Statistics/CompanyUsage'), loading: LoadingComponent}),
                                "bussinessNo": 2100003,
                                "exact": true
                              },
                              {
                                "name": "report-amount",
                                "path": "/admin**/business/statistics/report-amount",
                                "locale": "menu.business.statistics.report-amount",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Statistics__ReportAmount' */'D:/YLX_PET_WEB/xinguanjia_web_antdpro/src/pages/Business/Statistics/ReportAmount'), loading: LoadingComponent}),
                                "bussinessNo": 2100004,
                                "exact": true
                              },
                              {
                                "name": "doctor-report",
                                "path": "/admin**/business/statistics/doctor-report",
                                "locale": "menu.business.statistics.doctor-report",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Statistics__DoctorReport' */'D:/YLX_PET_WEB/xinguanjia_web_antdpro/src/pages/Business/Statistics/DoctorReport'), loading: LoadingComponent}),
                                "bussinessNo": 2100002,
                                "exact": true
                              }
                            ]
                          },
                          {
                            "path": "/admin**/business/pet",
                            "name": "pet",
                            "icon": "FundOutlined",
                            "iconCls": "&#xe61f;",
                            "id": 345,
                            "routes": [
                              {
                                "name": "pet-record",
                                "path": "/admin**/business/pet/pet-record",
                                "locale": "menu.business.pet.pet-record",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Pet__PetRecord' */'D:/YLX_PET_WEB/xinguanjia_web_antdpro/src/pages/Business/Pet/PetRecord'), loading: LoadingComponent}),
                                "bussinessNo": 5000011,
                                "exact": true
                              },
                              {
                                "name": "ai-report",
                                "path": "/admin**/business/pet/ai-report",
                                "locale": "menu.business.pet.ai-report",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Pet__AIReport' */'D:/YLX_PET_WEB/xinguanjia_web_antdpro/src/pages/Business/Pet/AIReport'), loading: LoadingComponent}),
                                "bussinessNo": 5000012,
                                "exact": true
                              },
                              {
                                "name": "interpretation-report",
                                "path": "/admin**/business/pet/interpretation-report",
                                "locale": "menu.business.pet.interpretation-report",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Pet__InterpretationReport' */'D:/YLX_PET_WEB/xinguanjia_web_antdpro/src/pages/Business/Pet/InterpretationReport'), loading: LoadingComponent}),
                                "bussinessNo": 5000013,
                                "exact": true
                              },
                              {
                                "name": "telemetry-report",
                                "path": "/admin**/business/pet/telemetry-report",
                                "locale": "menu.business.pet.telemetry-report",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Pet__TelemetryReport' */'D:/YLX_PET_WEB/xinguanjia_web_antdpro/src/pages/Business/Pet/TelemetryReport'), loading: LoadingComponent}),
                                "bussinessNo": 5000014,
                                "exact": true
                              },
                              {
                                "name": "monitor-records",
                                "path": "/admin**/business/pet/monitor-records",
                                "locale": "menu.business.pet.monitor-records",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Pet__MonitorRecords' */'D:/YLX_PET_WEB/xinguanjia_web_antdpro/src/pages/Business/Pet/MonitorRecords'), loading: LoadingComponent}),
                                "bussinessNo": 2000013,
                                "exact": true
                              },
                              {
                                "name": "pet-reports",
                                "path": "/admin**/business/pet/pet-reports",
                                "locale": "menu.business.pet.pet-reports",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Pet__PetReports' */'D:/YLX_PET_WEB/xinguanjia_web_antdpro/src/pages/Business/Pet/PetReports'), loading: LoadingComponent}),
                                "bussinessNo": 5000015,
                                "exact": true
                              },
                              {
                                "name": "quick-reports",
                                "path": "/admin**/business/pet/quick-reports",
                                "locale": "menu.business.pet.quick-reports",
                                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Business__Pet__QuickReports' */'D:/YLX_PET_WEB/xinguanjia_web_antdpro/src/pages/Business/Pet/QuickReports'), loading: LoadingComponent}),
                                "bussinessNo": 5000017,
                                "exact": true
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "name": "login",
                    "path": "/login",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__LoginPet' */'D:/YLX_PET_WEB/xinguanjia_web_antdpro/src/pages/LoginPet'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/welcome",
                    "name": "welcome",
                    "icon": "smile",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Welcome' */'D:/YLX_PET_WEB/xinguanjia_web_antdpro/src/pages/Welcome'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'D:/YLX_PET_WEB/xinguanjia_web_antdpro/src/pages/404'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              }
            ]
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'D:/YLX_PET_WEB/xinguanjia_web_antdpro/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      }
    ]
  },
  {
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'D:/YLX_PET_WEB/xinguanjia_web_antdpro/src/pages/404'), loading: LoadingComponent}),
    "exact": true
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
