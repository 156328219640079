/*
 * @Author: zhangzhipeng
 * @Date: 2021-11-26 09:33:34
 * @LastEditors: zhangzhipeng
 * @LastEditTime: 2022-11-08 10:39:18
 * @Description: 封装的API proxy
 */

import { Mobx } from '@/store';
import type { API } from './api';
import api from './api';
import { BussinessNo } from './BussinessNo';
import http, { CONFIG_HTTP } from './http';
import qs from 'qs';
import { get_query } from './tools';
import { deepCopy } from '@/utils/global/data';

export type PROXY_FUNC_DATA = {
  /**
   *默认get
   *
   * @type {('put' | 'delete' | 'get' | 'post')}
   */
  request?: 'put' | 'delete' | 'get' | 'post';
  /**
   *
   *传参
   * @type {Record<string, any>}
   */
  params: Record<string, any>;
  /**
 * ```js
 * 配置,默认会把url里的ecgId,repId,oper透传,并算出bussi
   //弹窗：传值是要弹出的key
   openwindow?: string;
  //下载：传值是要下载的key
   download?: string;
  //请求头
   headers?: Record<string, any>;
   responseType?: 'json' | 'text' | 'blob' | 'arrayBuffer' | 'formData' | undefined;
   // 是否显示屏蔽罩

   loading?: boolean;
  // 弹窗：传值是key

   alert?: string;
   // 弹出想显示的字符串

   msg?: string;
 * ```
 */
  config?: CONFIG_HTTP;
};
function proxys_func(): API {
  // @ts-ignore
  const res: API = {};
  const keys = Object.keys(api);
  const query = get_query();
  let keyId: any = query.keyId;
  const oper: any = query.oper || '2000025.MODIFY';
  // const oper: any=Mobx.EcgPublic?.headers?.oper
  let moduleType: any = undefined;

  if (query?.repId) {
    keyId = query.repId;
    moduleType = 'rep';
  }

  if (query?.ecgId) {
    keyId = query.ecgId;
    moduleType = 'ecg';
  }
  if (query?.token) {
    keyId = query.keyId;
    moduleType = 'rep';
  }
  console.log('window.location.hash', window.location.hash);
  console.log('queryobj', query);
  console.log('moduleType', moduleType);
  console.log('keyId', keyId);
  for (let i = 0; i < keys.length; i++) {
    res[keys[i]] = async function (data: PROXY_FUNC_DATA) {
      const params_moduleType = data?.params?.moduleType;
      let params_bussi = data?.params?.bussi ? { bussi: data?.params?.bussi } : {};
      if (params_moduleType) {
        params_bussi = { bussi: params_moduleType };
      }
      let headers = { oper };
      if (data.config?.headers) {
        headers = { oper, ...data.config?.headers };
      }
      const data_new = Object.assign(
        { ...{ bussi: moduleType, keyId }, ...params_bussi },
        data.params,
      );
      return http[data.request ? data.request : 'get'](api[keys[i]], data_new, {
        ...data.config,
        headers,
      });
    };
  }
  return res;
}
/**
 * ```js
 * 配置,默认会把url里的ecgId,repId,oper透传,并算出bussi
{
//默认get
     request?: 'put' | 'delete' | 'get' | 'post',
    //传参
     params: {
    //弹窗：传值是要弹出的key
    openwindow?: string;
   //下载：传值是要下载的key
    download?: string;
   //请求头
    headers?: Record<string, any>;
    responseType?: 'json' | 'text' | 'blob' | 'arrayBuffer' | 'formData' | undefined;
    // 是否显示屏蔽罩
    loading?: boolean;
   // 弹窗：传值是key
    alert?: string;
    // 弹出想显示的字符串
    msg?: string;}
    config?: CONFIG_HTTP
 }
 * ```
 */
export const proxys: API = proxys_func();

export default proxys;
