/*
 * @Author: zhangzhipeng
 * @Date: 2021-02-07 17:29:39
 * @LastEditors: zhangzhipeng
 * @LastEditTime: 2022-09-23 13:53:50
 * @Description: 文件说明
 */
import { Settings as ProSettings } from '@ant-design/pro-layout';

type DefaultSettings = Partial<ProSettings> & {
  pwa: boolean;
};
type CONFIG = {
  /**
   *关闭浏览器强制掉登录
   *
   * @type {boolean}
   */
  logout: boolean;
  /**
   *开启多任务
   *
   * @type {boolean}
   */
  tabs: boolean;
  /**
   *首页
   *
   * @type {{ title: string; path: string }}
   */
  index: { title: string; path: string };
  /**
   *列表简易搜索
   *
   * @type {boolean}
   */
  searchTableLight: boolean;
  zoom: boolean;
  spin: boolean;
};

export const proSettings: DefaultSettings & { primaryColorString: string } = {
  navTheme: 'dark', //'light',dark
  // navTheme: undefined,
  // 拂晓蓝
  // primaryColor: '51,183,203', // 主题颜色
  primaryColor: '#11828a', // 主题颜色
  primaryColorString: '17,130,138', // 主题颜色
  layout: 'side',
  contentWidth: 'Fluid',
  fixedHeader: false,
  fixSiderbar: true,
  colorWeak: false,
  title: '易连心心电工作站',
  pwa: false,
  iconfontUrl: '',
};
export const config: CONFIG = {
  logout: process.env.NODE_ENV === 'development' ? true : true, // 关闭浏览器强制掉登录
  tabs: true, // 开启多任务
  searchTableLight: false, // 简易搜索
  index: { title: '专家判读报告', path: 'interpretation-report' },
  // 默认开启自适应
  zoom: false,
  // 开启小屏限制
  spin: process.env.NODE_ENV === 'development' ? true : true,
};
export type { DefaultSettings };

export default { proSettings, config };
