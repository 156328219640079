/*
 * @Author: zhangzhipeng
 * @Date: 2021-03-17 16:57:07
 * @LastEditors: zhangzhipeng
 * @LastEditTime: 2023-03-15 10:36:27
 * @Description: 文件说明
 */
/**
 * 在生产环境 代理是无法生效的，所以这里没有生产环境的配置
 * The agent cannot take effect in the production environment
 * so there is no configuration of the production environment
 * For details, please see
 * https://pro.ant.design/docs/deploy
 */

// import { ENV } from '../src/utils/global/data';
//const ecg = '//192.168.10.88:8080';

const ndev = ['//dev.zxthealth.com:8084'];
const decg = ['//pet.zxthealth.com:8096', '//dev-pet.zxthealth.com:8096'];
const ecg = [
  '//petecg.zxthealth.com:8196',
  '//tecgpro.zxthealth.com:8086',
  '//trhc.zxthealth.com:8086',
  '//tecgpro.ekgexpress.com:8086',
];
const zxapi = ['//tecgpro.zxthealth.com', '//trhc.zxthealth.com', '//tecgpro.ekgexpress.com'];
const tecg = ['//pet.zxthealth.com:8096'];
const beta = [
  '//ecgpro.zxthealth.com:8082',
  '//rhc.zxthealth.com:8082',
  '//ecgpro.ekgexpress.com:8082',
];
export const all = { ndev, ecg, beta, tecg, decg };
const WS = process.argv[2] ? process.argv[2] : 'dev';
const ws = '/' + WS + '/zxws/';

const def = {
  '/zxapi/zxws/': {
    target: `ws:${zxapi[0]}`,
    changeOrigin: true,
    ws: true,
    pathRewrite: { '^': '' },
  },
  '/ecg/zxws/': {
    target: `ws:${all['ecg'][0]}`,
    changeOrigin: true,
    ws: true,
    pathRewrite: { '^': '' },
  },
  '/beta/zxws/': {
    target: `ws:${all['beta'][0]}`,
    changeOrigin: true,
    ws: true,
    pathRewrite: { '^': '' },
  },
  '/decg/zxws/': {
    target: `ws:${all['decg'][0]}`,
    changeOrigin: true,
    ws: true,
    pathRewrite: { '^': '' },
  },
  '/ndev/zxws/': {
    target: `ws:${all['ndev'][0]}`,
    changeOrigin: true,
    ws: true,
    pathRewrite: { '^': '' },
  },
  '/zxapi/': {
    target: 'http:' + zxapi[0],
    changeOrigin: true,
    pathRewrite: { '^': '' },
  },
  '/ecg/': {
    target: 'http:' + ecg[0],
    changeOrigin: true,
    pathRewrite: { '^': '' },
  },
  '/video/SystemPro/': {
    target: 'http://www.zxthealth.com',
    changeOrigin: true,
    pathRewrite: { '^': '' },
  },
  '/tecg/': {
    target: 'http:' + tecg[0],
    changeOrigin: true,
    pathRewrite: { '^': '' },
  },
  '/ndev/': {
    target: 'http:' + ndev[0],
    changeOrigin: true,
    pathRewrite: { '^': '' },
  },
  '/beta/': {
    target: 'http:' + beta[0],
    changeOrigin: true,
    pathRewrite: { '^': '' },
  },
  '/decg/': {
    target: 'http:' + decg[0],
    changeOrigin: true,
    pathRewrite: { '^': '' },
  },
};
export default {
  //于孟田
  'ecg@ymt': {
    ...def,
    ...{
      '/dev/zxws/': {
        target: 'ws://192.168.10.111:8081',
        changeOrigin: true,
        ws: true,
        pathRewrite: { '^': '' },
      },
      '/dev/': {
        target: 'http://192.168.10.111:8081',
        changeOrigin: true,
        pathRewrite: { '^': '' },
      },
    },
  },
  //张志鹏
  'zxapi@zzp': {
    ...def,
    ...{
      '/dev/zxws/': {
        target: 'ws://192.168.10.110:8081',
        changeOrigin: true,
        ws: true,
        pathRewrite: { '^': '' },
      },
      '/dev/': {
        target: 'http://192.168.10.110:8081',
        changeOrigin: true,
        pathRewrite: { '^': '' },
      },
    },
  },
  //张志鹏
  'ecg@zzp': {
    ...def,
    ...{
      '/dev/zxws/': {
        target: 'ws://192.168.10.110:8081',
        changeOrigin: true,
        ws: true,
        pathRewrite: { '^': '' },
      },
      '/dev/': {
        target: 'http://192.168.10.110:8081',
        changeOrigin: true,
        pathRewrite: { '^': '' },
      },
    },
  },
  //张志鹏
  'ecg@mock': {
    ...def,
    ...{
      '/dev/zxws/': {
        target: 'ws://192.168.10.110:8081',
        changeOrigin: true,
        ws: true,
        pathRewrite: { '^': '' },
      },
      '/dev/': {
        target: 'http://192.168.10.110:8081',
        changeOrigin: true,
        pathRewrite: { '^': '' },
      },
    },
  },
  // 李荃荃
  'ecg@lqq': {
    ...def,
    ...{
      '/dev/zxws/': {
        target: 'ws://192.168.10.113:8080',
        changeOrigin: true,
        ws: true,
        pathRewrite: { '^': '' },
      },
      '/dev/': {
        target: 'http://192.168.10.113:8080',
        changeOrigin: true,
        pathRewrite: { '^': '' },
      },
    },
  },
  // 周京镇
  'ecg@zjz': {
    ...def,
    ...{
      '/dev/zxws/': {
        target: 'ws://192.168.10.113:8080',
        changeOrigin: true,
        ws: true,
        pathRewrite: { '^': '' },
      },
      '/dev/': {
        target: 'http://192.168.10.113:8080',
        changeOrigin: true,
        pathRewrite: { '^': '' },
      },
    },
  },
  'ecg@ecg': {
    ...def,
    ...{
      '/dev/zxws/': {
        target: 'ws://192.168.10.113:8080',
        changeOrigin: true,
        ws: true,
        pathRewrite: { '^': '' },
      },
      '/dev/': {
        target: 'http://192.168.10.113:8080',
        changeOrigin: true,
        pathRewrite: { '^': '' },
      },
    },
  },
  'ndev@ndev': {
    ...def,
    ...{
      '/dev/zxws/': {
        target: 'ws://192.168.10.113:8080',
        changeOrigin: true,
        ws: true,
        pathRewrite: { '^': '' },
      },
      '/dev/': {
        target: 'http://192.168.10.113:8080',
        changeOrigin: true,
        pathRewrite: { '^': '' },
      },
    },
  },

  'dev@dev': {
    ...def,
    ...{
      '/dev/zxws/': {
        target: 'ws://192.168.10.113:8080',
        changeOrigin: true,
        ws: true,
        pathRewrite: { '^': '' },
      },
      '/dev/': {
        target: 'http://192.168.10.113:8080',
        changeOrigin: true,
        pathRewrite: { '^': '' },
      },
    },
  },
  'beta@beta': {
    ...def,
    ...{
      '/dev/zxws/': {
        target: 'ws://192.168.10.113:8080',
        changeOrigin: true,
        ws: true,
        pathRewrite: { '^': '' },
      },
      '/dev/': {
        target: 'http://192.168.10.113:8080',
        changeOrigin: true,
        pathRewrite: { '^': '' },
      },
    },
  },
  'ecg@mfsu': {
    ...def,
    ...{
      '/dev/zxws/': {
        target: 'ws://192.168.10.113:8080',
        changeOrigin: true,
        ws: true,
        pathRewrite: { '^': '' },
      },
      '/dev/': {
        target: 'http://192.168.10.113:8080',
        changeOrigin: true,
        pathRewrite: { '^': '' },
      },
    },
  },
  test: {
    '.*[.]action': {
      // target: 'http://192.168.199.217:8080',
      target: 'http://192.168.199.230:8080',
      changeOrigin: true,
      pathRewrite: { '^': '' },
    },
  },
  pre: {
    '.*[.]action': {
      // target: 'http://192.168.199.217:8080',
      target: 'http://192.168.199.230:8080',
      changeOrigin: true,
      pathRewrite: { '^': '' },
    },
  },
};
